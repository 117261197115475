import axios from 'axios';
import { Match } from '../CandidatesScreen/models/Match';
import { GeneticTestingResult } from '../OnboardingForm/Steps/GeneticTestStep/models/GeneticTestingResult';
import { ChatMessage } from '../OnboardingForm/Steps/ChatBotStep/ChatMessages';
import { OnboardingFormResults } from '../OnboardingForm/FormController/OnboardinFormResults';

const API_URL = 'https://next-gene-back-zeta.vercel.app';
// const API_URL = 'http://localhost:5000';

const apiClient = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const handleError = (error: any) => {
    // Handle errors here (e.g., log, show a notification)
    console.error('API call failed:', error);
    throw error; // Re-throw the error for further handling if necessary
};

export const getMatchingResults = async (): Promise<Match[]> => {
    try {
        const response = await apiClient.get<Match[]>('/candidates');
        return response.data;
    } catch (error) {
        handleError(error);
        return []; // Return an empty array in case of error
    }
};

export const generateNextChatMessage = async (chatMessages: ChatMessage[], formObject: OnboardingFormResults): Promise<string | undefined> => {
    try {
        const response = await apiClient.post(
            '/onboarding-form/chat/generate-next-message',
            {
                chat_messages: chatMessages,
                form_answers: formObject
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data
    } catch (error) {
        handleError(error);
        return undefined
    }
};

export const initializeChat = async (): Promise<ChatMessage[] | undefined> => {
    try {
        const response = await apiClient.post(
            '/onboarding-form/chat/initialize-chat',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data
    } catch (error) {
        handleError(error);
        return undefined
    }
};

export const submitGeneticTesting = async (): Promise<GeneticTestingResult[] | undefined> => {

    function delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    try {
        await delay(10000);
        const response = [
            {
                name: 'ACSF3',
                explnation: 'Combined Malonic and Methylmalonic Aciduria (CMAMMA) is a rare metabolic disorder caused by the dysfunction of enzymes involved in the metabolism of malonic and methylmalonic acids, leading to their accumulation in the body.',
                isPositive: true
            },
            {
                name: 'DLD',
                explnation: 'Lipoamide Dehydrogenase Deficiency is a rare metabolic disorder affecting the enzyme responsible for energy production, leading to issues with energy metabolism, neurological symptoms, and lactic acidosis.',
                isPositive: true
            }
        ]
        return response
    } catch (error) {
        handleError(error);
        return []
    }
};

export const authenticate = async (password: string): Promise<boolean> => {
    try {
        const response = await apiClient.post(
            '/authentication/authenticate',
            {
                password: password
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data
    } catch (error) {
        handleError(error);
        return false
    }
};