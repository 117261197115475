import { Box, TextField } from "@mui/material";
import { useState } from "react";
import "./Chat.css"
import { ReactComponent as SendMessageIcon } from "./icons/send_message_button.svg"
import { ChatMessage } from "./ChatMessages";

interface MessageGeneratorProps {
    addNewmMessage: (chatMessages: ChatMessage[]) => void
}

export function MessageGenerator({ addNewmMessage }: MessageGeneratorProps) {

    const [text, setText] = useState("");

    const sendMessage = () => {
        addNewmMessage([{ message: text, speaker: "CLIENT" }])
        setText(""); // Clear the input field
    }

    const handleKeyDown = async (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && text.trim()) {
            sendMessage()
        }
    };

    return (
        <Box className="MessageGenerator">
            <TextField
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
                variant="outlined"
                sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            border: "none",
                        },
                    },
                }}
            />
            <SendMessageIcon onClick={sendMessage} />
        </Box>
    );
}
