import { Box, Typography } from "@mui/material";
import "./Chat.css"
import { ReactComponent as AIEngine } from "./icons/ai_engine.svg"

interface ChatMessageProps {
    speaker: string
    message: string
}

export function ChatMessageComponent({ message, speaker: speker }: ChatMessageProps) {

    return <Box className="ChatMessage" sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: speker == "BOT" ? "flex-start" : 'flex-end',
        gap: "10px",
        animation: "popIn 0.3s ease-out"
    }}>
        {speker == "BOT" && <AIEngine />}
        <Box className="ChatTextPlaceHolder">
            <Typography textAlign="left"> {message} </Typography>
        </Box>
    </Box>
}
