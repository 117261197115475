import { StepProps } from "../steps";
import "./ChatBot.css"
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "./Chat.css"
import { generateNextChatMessage, initializeChat } from "./../../../Service/http";
import { useFormContext } from "./../../Context/FormContext";
import { MessageGenerator } from "./MessageGenerator";
import { ChatMessageComponent } from "./ChatMessageComponent";
import { ChatMessage } from "./ChatMessages";

export function ChatBot({ id, updateForm }: StepProps) {
    const { formObject } = useFormContext();
    const [counter, setCounter] = useState(0);
    const messageContainerRef = useRef<HTMLDivElement | null>(null);

    const addNewMessages = (messages: ChatMessage[]) => {
        const newMessages = [...formObject.ChatBot.answer, ...messages]
        updateForm(id, newMessages)
    }

    useEffect(() => {
        const initializeChatFn = async () => {
            const messages = await initializeChat()
            if (messages) {
                addNewMessages(messages)
            }
        }
        initializeChatFn()
        const interval = setInterval(() => {
            setCounter((prevCounter) => {
                return prevCounter === 365 ? 0 : prevCounter + 1;
            });
        }, 10);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const results = await generateNextChatMessage(formObject.ChatBot.answer, formObject)
            addNewMessages([{ message: results!, speaker: "BOT" }])
        }
        if (formObject.ChatBot.answer.length > 0 && formObject.ChatBot.answer[formObject.ChatBot.answer.length - 1].speaker == "CLIENT") {
            fetchData();
        }
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [formObject.ChatBot.answer]);

    return <Box sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        background: `linear-gradient(${counter}.1deg, #EFAAF7 12.62%, #90B6FF 44.71%, #F7C8CF 76.79%)`,
        borderRadius: "15px"
    }}>
        <Box className="ChatWindow" sx={{ borderRadius: "15px" }}>
            <Box className="MessagesContainer" ref={messageContainerRef}>
                {
                    formObject.ChatBot.answer.map((message, index) => (<ChatMessageComponent key={index} message={message.message} speaker={message.speaker} />))
                }
            </Box>
            <MessageGenerator addNewmMessage={addNewMessages} />
        </Box>
    </Box>
}
