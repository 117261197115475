import { Box } from "@mui/material"
import "./OnboardingForm.css"
import FormAnswers from "../FormAnswers/FormAnswers";
import { StepID, characteristicsStep, stepComponentFactory, steps, chatBotStep } from "../Steps/steps";
import { useState } from "react";
import CustomizedSlider from "./ProgressBar/ProgressBar";
import { useFormContext } from "../Context/FormContext";
import { OnboardingFormResults } from "./OnboardinFormResults";
import { QuestionTitle } from "./QuestionTitle";
import { ButtonsPannel } from "./ButtonsPanel";
import backgroundImage from '../../../Background/background.svg';
import specialStepBackgroundImage from '../../../Background/special_step_background.svg';

interface OnboardingFormProps {
    onboardingFormFinished: () => void
}

function OnboardingForm({ onboardingFormFinished }: OnboardingFormProps) {

    const { formObject, updateFormObject, currentStep, updateCurrentStep } = useFormContext();

    const updateOnboardingFormWithAnswer = (stepID: StepID, answer: Partial<OnboardingFormResults>) => {
        updateFormObject(stepID, answer, 'Answered')
    }

    const handleSkipClicked = (stepDefaultAnswer: any) => {
        updateFormObject(steps[currentStep].id, stepDefaultAnswer, 'Skipped')
        if (currentStep == steps.length - 1) {
            onboardingFormFinished()
        }
        updateCurrentStep(currentStep + 1);
    };

    const handleNextClick = () => {
        if (currentStep == steps.length - 1) {
            onboardingFormFinished()
        }
        updateCurrentStep(currentStep + 1);
    };

    const handleBackClick = () => {
        if (currentStep != 0) {
            updateCurrentStep(currentStep - 1);
        }
    };

    const isNextDisabled = () => {
        const currentStepID = steps[currentStep].id
        const stepAnswer = formObject[currentStepID].answer
        if (Array.isArray(stepAnswer)) {
            return stepAnswer.length === 0
        }
        const answerIsDefault = stepAnswer == steps[currentStep].defaultAnswer
        return answerIsDefault
    }

    const rightPanelFiller = () => {
        return <FormAnswers />
    }

    return <Box className="Form" sx={{ backgroundImage: backgroundImage, backgroundSize: "cover" }}>
        <Box className="FormController">
            <CustomizedSlider value={currentStep * 100 / steps.length} />
            <Box className="Question">
                <QuestionTitle step={steps[currentStep]} />
                {stepComponentFactory(steps[currentStep].id, updateOnboardingFormWithAnswer, handleNextClick, handleBackClick)}
            </Box>
            {
                <ButtonsPannel
                    currentStep={currentStep}
                    isNextDisabled={isNextDisabled()}
                    handleNextClicked={handleNextClick}
                    handleBackClicked={handleBackClick}
                    handleSkipClicked={handleSkipClicked}
                />
            }
        </Box>
        {rightPanelFiller()}
    </Box>
}

export default OnboardingForm