import * as React from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { ReactComponent as Pacifier } from './pacifier.svg';
import { Box, Typography } from '@mui/material';
import "../OnboardingForm.css"

const OnboardingFormSlider = styled(Slider)(({ theme }) => ({
    height: "70px",
    '& .MuiSlider-thumb': {
        height: 32,
        width: 32,
        backgroundColor: 'transparent',
    },
    '& .MuiSlider-track': {
        height: '8px',
        boxShadow: '0px 4px 16px 0px #D3D3FF80',
        background: 'linear-gradient(90deg, #BFBEFF 0%, #90B6FF 100%)',
    },
    '& .MuiSlider-rail': {
        boxShadow: '0px 4px 16px 0px #D3D3FF80',
        height: '8px',
        backgroundColor: '#fff',
        opacity: 1,
    },
}));


interface OnboardingFormSliderComponent extends React.HTMLAttributes<unknown> { }

function OnboardingFormSliderComponent(props: OnboardingFormSliderComponent) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other} >
            <Pacifier />
        </SliderThumb>
    );
}

interface CustomizedSliderProps {
    value: number
}

export default function CustomizedSlider({ value }: CustomizedSliderProps) {
    return <Box className="CustomizedSlider">
        <OnboardingFormSlider
            slots={{ thumb: OnboardingFormSliderComponent }}
            value={value}
            disabled={true}
        />
        <Typography> {Math.trunc(value)}% Completed </Typography>
    </Box>
}